<!-- 菜单信息页面（新增、编辑、详情） -->
<template>
  <div>
    <a-modal
      :title="title"
      width="38%"
      okText="确定"
      cancelText="取消"
      v-model:visible.sync="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <a-form ref="menuForm" :model="menuInfo" :rules="rules">
          <a-form-item
            v-show="isCreate > 3"
            label="主菜单名称:"
            name="mainname"
            :labelCol="formLayout1X1.labelCol"
            :wrapperCol="formLayout1X1.wrapperCol"
          >
            <a-input v-model:value="mainMenuInfo.name" type="text" disabled>
            </a-input>
          </a-form-item>
          <a-form-item
            :label="isCreate < 4 ? '主菜单名称' : '子菜单名称'"
            name="name"
            :labelCol="formLayout1X1.labelCol"
            :wrapperCol="formLayout1X1.wrapperCol"
          >
            <a-input
              v-model:value="menuInfo.name"
              type="text"
              :readonly="isCreate % 3 == 0 ? true : false"
              placeholder="请输入菜单名称"
            >
              <template v-slot:prefix>
                <EditOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            :label="isCreate < 4 ? '主菜单图标' : '子菜单图标'"
            name="iconUrl"
            :labelCol="formLayout1X1.labelCol"
            :wrapperCol="formLayout1X1.wrapperCol"
          >
            <a-input
              v-model:value="menuInfo.iconUrl"
              type="text"
              :readonly="isCreate % 3 == 0 ? true : false"
              placeholder="请输入菜单图标"
            >
              <template v-slot:prefix>
                <EditOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            label="组件名称:"
            name="menuCode"
            :labelCol="formLayout1X1.labelCol"
            :wrapperCol="formLayout1X1.wrapperCol"
          >
            <a-input
              v-model:value="menuInfo.menuCode"
              type="text"
              :readonly="isCreate % 3 == 0 ? true : false"
              placeholder="请输入组件名称"
            >
              <template v-slot:prefix>
                <EditOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            label="组件路径:"
            name="linkUrl"
            :labelCol="formLayout1X1.labelCol"
            :wrapperCol="formLayout1X1.wrapperCol"
          >
            <a-input
              v-model:value="menuInfo.linkUrl"
              type="text"
              :readonly="isCreate % 3 == 0 ? true : false"
              placeholder="请输入组件路径"
            >
              <template v-slot:prefix>
                <EditOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            label="访问路由:"
            name="path"
            :labelCol="formLayout1X1.labelCol"
            :wrapperCol="formLayout1X1.wrapperCol"
          >
            <a-input
              v-model:value="menuInfo.path"
              type="text"
              :readonly="isCreate % 3 == 0 ? true : false"
              placeholder="请输入访问路由"
            >
              <template v-slot:prefix>
                <EditOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item
            label="排序:"
            name="sort"
            :labelCol="formLayout1X1.labelCol"
            :wrapperCol="formLayout1X1.wrapperCol"
          >
            <a-input-number
              style="width: 100%"
              v-model:value="menuInfo.sort"
              :min="1"
              :max="20"
              :readonly="isCreate % 3 == 0 ? true : false"
              placeholder="请输入排序"
            />
          </a-form-item>
          <a-form-item
            label="功能说明:"
            name="memo"
            :labelCol="formLayout1X1.labelCol"
            :wrapperCol="formLayout1X1.wrapperCol"
          >
            <a-textarea
              :readonly="isCreate % 3 == 0 ? true : false"
              v-model:value="menuInfo.memo"
              placeholder="填写功能说明"
              :rows="2"
            />
          </a-form-item>
        </a-form>
      </div>

      <template #footer>
        <a-button v-show="isCreate % 3 > 0" @click="handleCancel"
          >取消</a-button
        >
        <a-button v-show="isCreate % 3 > 0" type="primary" @click="handleOk"
          >确定</a-button
        >
      </template>
    </a-modal>
  </div>
</template>

<script>
import { EditOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, ref, watch } from "vue";
import { message } from "ant-design-vue";
import { saveMenu } from "@/api/menu";
export default {
  name: "MenuForm",
  components: { EditOutlined },
  // 接受父组件传递的值
  props: {
    modalVisible: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    editData: {
      type: Object,
      default: () => {},
    },
    mainInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, context) {
    const menuForm = ref();
    const state = reactive({
      visible: false,
      confirmLoading: false,
      title: "",
      mainMenuInfo: {
        mid: null,
        name: "",
      },
      menuInfo: {
        parentId: null,
        id: null,
        name: "",
        menuCode: "",
        linkUrl: "",
        iconUrl: "",
        path: "",
        sort: null,
        memo: "",
        enable: true,
        keepAlive: true,
      },
      formLayout1X1: {
        labelCol: {
          span: 5,
        },
        wrapperCol: {
          span: 16,
        },
      },
    });
    const handleOk = () => {
      menuForm.value
        .validate()
        .then((success) => {
          if (success) {
            console.log(state.menuInfo);
            state.confirmLoading = true;
            saveMenu(state.menuInfo).then((resp) => {
              if (resp) {
                menuForm.value.resetFields();
                setTimeout(() => {
                  if (props.isCreate < 3) {
                    context.emit("initMainMenuList");
                  } else {
                    context.emit("initSubMenuList", state.menuInfo.parentId);
                  }
                  context.emit("handleModalVisible", false);
                  state.confirmLoading = false;
                }, 1000);
              }
            });
          } else {
            if (props.isCreate % 3 == 1) {
              message.error("菜单添加失败！！！");
            } else {
              message.error("菜单编辑失败！！！");
            }
            return false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    };
    const handleCancel = () => {
      context.emit("handleModalVisible", false);
    };
    const rules = {
      name: [{ required: true, message: "菜单名称不能为空", trigger: "blur" }],
      menuCode: [
        { required: true, message: "组件名称不能为空！", trigger: "blur" },
      ],
      linkUrl: [
        { required: true, message: "组件路径不能为空！", trigger: "blur" },
      ],
      iconUrl: [
        { required: true, message: "菜单图标不能为空！", trigger: "blur" },
      ],
      path: [
        { required: true, message: "访问路径不能为空！", trigger: "blur" },
      ],
      sort: [
        {
          required: true,
          message: "排序不能为空！",
          type: "number",
          trigger: "blur",
        },
      ],
    };

    const refData = toRefs(state);
    watch(
      () => [props.editData, props.modalVisible, props.title, props.mainInfo],
      ([nv1, nv2, nv3, nv4]) => {
        state.visible = nv2;
        state.menuInfo = nv1;
        state.title = nv3;
        state.mainMenuInfo = nv4;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      ...refData,
      menuForm,
      rules,
      handleCancel,
      handleOk,
    };
  },
};
</script>
<style lang='scss' scoped>
</style>